<template>
  <div class="downloads">
    <div class="downloads__w">
      <PageHeader :product="product" :prepend="'@Downloads for'" />
      <div class="downloads__container">
        <div class="downloads__header">
          <span v-for="name in columnNames" class="downloads__header-name">
            {{ _T(name) }}
          </span>
        </div>
        <div
          v-for="downloadsItem in product.getDownloads()"
          :key="downloadsItem.group"
          class="downloads__column"
        >
          <p class="downloads__list-name">
            {{ downloadsItem.name }}
          </p>
          <ul class="downloads__column">
            <DownloadsFile
              v-for="(file, index) in downloadsItem.files"
              :key="index"
              :file="file"
            />
          </ul>
        </div>
      </div>
    </div>
    <AsideCard :product="product" :navigation-height="navigationHeight" />
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import DownloadsFile from "~/modules/product/ui/pages/Downloads/DownloadsFile.vue";
import PageHeader from "~/modules/product/ui/components/PageHeader.vue";
import AsideCard from "~/modules/product/ui/components/AsideCard.vue";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
});

const patternSeo = {
  metaTitle: {
    postfix: () => _T("@Instructions"),
  },
  metaDescription: {
    prefix: () => _T(" @Instructions, application, drivers"),
    postfix: () => _T("@online store") + " | GreenVision",
  },
  metaKeywords: {
    postfix: () => _T("@Instructions"),
  },
};

configureProductSeo(props.product, patternSeo);

const columnNames = ["@Type", "@Date", "@Language", "@File type", "@Loading"];
</script>

<style lang="scss">
.downloads {
  @include flex-container(row, center, flex-start);
  gap: 44px;

  @include bigMobile {
    gap: 16px;
  }

  &__container {
    border: 1px solid var(--color-sky-base);
    border-radius: 8px;

    padding-bottom: 16px;
  }

  &__w {
    width: 100%;
    max-width: 880px;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__header {
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr) 1.5fr;
    justify-items: center;

    padding-inline: 16px;

    @include bigMobile {
      grid-template-columns: 2fr repeat(2, 1fr);
    }
  }

  &__header-name {
    @include font;
    letter-spacing: 0.02em;

    color: var(--color-sky-darker);

    padding-block: 16px;

    &:first-child {
      justify-self: flex-start;
    }

    &:last-child {
      justify-self: flex-end;
    }

    @include bigMobile {
      @include font(14, 16);

      &:nth-child(2n) {
        display: none;
      }
    }
  }

  &__column {
    width: 100%;

    @include flex-container(column, center, flex-start);
    gap: 16px;

    padding-bottom: 8px;

    @include mobile {
      gap: 8px;
    }
  }

  &__list-name {
    width: 100%;

    @include font(16, 22, 500);

    background-color: var(--color-sky-lighter);
    padding: 8px 16px;
  }

  &__file {
    width: 100%;

    display: grid;
    grid-template-columns: 32px 2fr repeat(4, 1fr);
    gap: 8px;

    justify-items: center;
    align-items: center;

    @include font;

    padding-inline: 16px;

    @include bigMobile {
      grid-template-columns: repeat(4, 1fr);

      @include font(14, 16);
    }
  }

  &__file-name {
    justify-self: flex-start;
  }

  &__file-date {
    @include bigMobile {
      display: none;
    }
  }

  &__languages {
    @include flex-container(row, center, center);

    gap: 4px;
  }

  &__file-type {
    @include bigMobile {
      display: none;
    }
  }

  &__file-link {
    width: fit-content;

    color: var(--color-primary-base);

    @include flex-container(row, flex-start, flex-start);
    gap: 8px;

    justify-self: flex-end;

    & span {
      border-bottom: 1px solid transparent;

      transition: 0.2s ease;
    }

    &:hover {
      & span {
        border-bottom: 1px solid var(--color-primary-base);
      }

      & .downloads__file-link-img {
        transform: translateY(2px);
      }
    }
  }

  &__file-link-img {
    transition: 0.2s ease;

    @include bigMobile {
      display: none;
    }
  }
}
</style>
