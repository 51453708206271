<template>
  <li class="downloads__file">
    <img
      class="downloads__file-icon"
      :src="`/img/file-${mime.extension(file.meta.type)}.svg`"
      alt="file type"
      loading="lazy"
      @error="onFileIconError"
    />
    <span class="downloads__file-name">
      {{ getLocalizedName(file.name) }}
    </span>
    <span class="downloads__file-date">
      {{ normalizeDate(file.createdAt) }}
    </span>
    <div class="downloads__languages">
      <span
        v-for="(locale, index) in file.locales"
        :key="index"
        class="downloads__file-lang"
      >
        {{ formatLangToCyrillic(locale) }}
      </span>
    </div>
    <span class="downloads__file-type">
      {{ getFileType(file.meta.type) }}
    </span>
    <a
      class="downloads__file-link"
      :href="file.url"
      target="_blank"
      rel="nofollow"
    >
      <span>{{ _T("@Download") }}</span>
      <img
        class="downloads__file-link-img"
        src="/img/download-arrow.svg"
        alt="download"
        loading="lazy"
      />
    </a>
  </li>
</template>

<script setup>
import * as mime from "mime-types";

const props = defineProps({
  file: { type: Object, required: true },
});

const cyrillicLangMap = {
  ru: "ру",
  uk: "укр",
  en: "англ",
};

const formatLangToCyrillic = (lang) => {
  return cyrillicLangMap[lang] ?? lang;
};

const onFileIconError = (error) => {
  error.target.src = "/img/file-false.svg";
};

const getFileType = (type) => {
  if (!mime.extension(type)) {
    return "";
  }

  if ("bin" === mime.extension(type)) {
    return "exe";
  }

  return mime.extension(type);
};
</script>

<style scoped lang="scss"></style>
